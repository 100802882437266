.hmArea {
  padding-bottom: 0;
  height: 320px;
  width: 400px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: transparent;
  position: relative;
}

.hmSupport {
  margin: 0 auto;
  width: 200px;
  height: 10px;
  font-size: 5rem;
  background-color: #00beff;
  position: relative;
  top: -300px;
  left: 0px;
}

.hmPole {
  margin: 0 auto;
  width: 10px;
  height: 300px;
  font-size: 5rem;
  background-color: #00beff;
  position: relative;
  top: 10px;
  left: -95px;
}

.hmBase {
  margin: 0 auto;
  width: 200px;
  height: 10px;
  font-size: 5rem;
  background-color: #00beff;
  position: relative;
  top: 0px;
  left: -50px;
}

.hmNeckSupport {
  margin: 0 auto;
  width: 10px;
  height: 30px;
  font-size: 5rem;
  background-color: #00beff;
  position: relative;
  left: 95px;
  top: -300px;
}

.hmHead {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 10px solid #00beff;
  position: relative;
  top: -300px;
  left: 95px;
}

.hmBody {
  margin: 0 auto;
  width: 10px;
  height: 100px;
  font-size: 5rem;
  background-color: #00beff;
  position: relative;
  top: -300px;
  left: 95px;
}

.hmLeftArm {
  margin: 0 auto;
  width: 80px;
  height: 10px;
  font-size: 5rem;
  background-color: #00beff;
  transform: rotate(160deg);
  transform-origin: bottom left;
  position: relative;
  top: -415px;
  left: 210px;
}

.hmRightArm {
  margin: 0 auto;
  width: 80px;
  height: 10px;
  font-size: 5rem;
  background-color: #00beff;
  transform: rotate(20deg);
  transform-origin: bottom right;
  position: relative;
  top: -390px;
  left: 50px;
}

.hmLeftLeg {
  margin: 0 auto;
  width: 80px;
  height: 10px;
  font-size: 5rem;
  background-color: #00beff;
  transform: rotate(225deg);
  transform-origin: bottom left;
  position: relative;
  left: 195px;
  top: -280px;
}

.hmRightLeg {
  margin: 0 auto;
  width: 80px;
  height: 10px;
  font-size: 5rem;
  background-color: #00beff;
  transform: rotate(310deg);
  transform-origin: bottom right;
  position: relative;
  left: 60px;
  top: -340px;
}
